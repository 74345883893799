import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const EmailCallout = () => {
    return (
        <div className="c-email-callout">
            <StaticImage
                className="c-email-callout__icon"
                src={'../../../images/email/icon-phone.png'}
                width={100}
                alt={'Phone icon'}
            />
            <ul>
                <li>Nothing is misunderstood</li>
                <li>Questions are answered</li>
                <li>All options are explored</li>
            </ul>
        </div>
    )
}

export default EmailCallout
